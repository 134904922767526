<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam.order_no" allowClear></a-input>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托单位名称">
                <a-input v-model="queryParam.customer_name" allowClear></a-input>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="订单类型">
                <a-select
                  allowClear
                  style="width: 100%"
                  v-model="queryParam.order_type"
                >
                  <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="费用状态">
                <a-select
                  allowClear
                  style="width: 100%"
                  v-model="queryParam.fee_status"
                >
                  <a-select-option v-for="op in GLOBAL.feeStatusMaps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="danger" @click="handleCheckFee('2')" :disabled="!this.selectedRowKeys.length">审核通过</a-button>
        <a-button type="primary" @click="handleCheckFee('1')" :disabled="!this.selectedRowKeys.length">反审核</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <span slot="orderTypeSlot" slot-scope="text">
          <a-tag :color="text === 2? 'blue':'green'">{{ text === 2? '应付':'应收' }}</a-tag>
        </span>
        <span slot="feeStatusSlot" slot-scope="text">
          <a-tag :color="text === 1 || text === 4? 'red':'green'">{{ GLOBAL.feeStatusMaps[text].name }}</a-tag>
        </span>
        <span slot="businessDateSlot" slot-scope="text, record">
          {{ record.business_date ? record.business_date : '' }}~
          {{ record.business_end_date ? record.business_end_date : '' }}
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable, EditTable } from '@/components'
import moment from 'moment'
import { queryCompositeFeePages, checkCompositeFees } from '@/api/mms'
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import debounce from 'lodash/debounce'
export default {
  components: {
    STable,
    EditTable
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {},
      orderTypeOps: [{ name: '应收', value: 1 }, { name: '应付', value: 2 }],
      columns: [
        {
          title: '业务编号',
          dataIndex: 'composite_order.order_no'
        },
        {
          title: '订单类型',
          dataIndex: 'composite_order.order_type',
          scopedSlots: { customRender: 'orderTypeSlot' }
        },
        {
          title: '委托单位名称',
          dataIndex: 'composite_order.customer_name'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text, record) => {
            return this.GLOBAL.finDepartment[text].name
          }
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusSlot' }
        },
        {
          title: '业务审核人',
          dataIndex: 'check_user_name'
        },
        {
          title: '业务日期',
          dataIndex: 'composite_order.business_date',
          scopedSlots: { customRender: 'businessDateSlot' }
        },
        {
          title: '备注',
          dataIndex: 'composite_order.remark'
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return queryCompositeFeePages(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      customerInfo: undefined,
      fetching: false,
      agencyOps: [],
      departmentOps: []
    }
  },
  created() {
    this.departmentOps = this.GLOBAL.finDepartment
    console.info(this.departmentOps)
  },
  methods: {
    moment,
    getCommonOptions,
    findOrderType(text) {
      console.info(this.orderTypeOps)
      this.orderTypeOps.forEach(item => {
        console.info(item)
        console.info(text)
        if (item.value === text) {
          console.info(item.name)
          return item.name
        }
      })
    },
    onDateBeginChange(date, dateString) {
      if (date) {
        const startDate = date.format('YYYY-MM-DD');
        this.form.business_date = startDate
      } else {
        this.form.business_date = null
      }
    },
    onDateEndChange(date, dateString) {
      if (date) {
        const startDate = date.format('YYYY-MM-DD');
        this.form.business_end_date = startDate
      } else {
        this.form.business_end_date = null
      }
    },
    clearCustomerInfo() {
      this.customerInfo = undefined
      this.agencyOps = []
      this.form.customer_id = null
      this.form.customer_name = null
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      let queryCustomerfunction = getSupplier
      if (this.form.order_type === 1) {
        queryCustomerfunction = getCustomer
      }
      queryCustomerfunction({
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    handleCheckFee(status) {
      const message = status === '1' ? '反审核' : '审核通过'
      this.$confirm({
        title: '确认提示',
        content: '确定要' + message + '？',
        onOk: () => {
          checkCompositeFees({ fee_ids: this.selectedRowKeys, fee_status: status }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: message + '成功.'
            })
            this.selectedRowKeys = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form.customer_id = value.key
      this.form.customer_name = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getFeeData(data) {
      this.feeData = data
    },
    handleDelFeeType(index) {
      this.feeData.splice(index, 1)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  }
}
</script>
